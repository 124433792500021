<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Name English</th>
                  <th>Name Bangla</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(address, index) in addresses.data"
                  :key="index"
                  v-if="addresses.data.length"
                >
                  <td class="align-middle text-center">
                    {{ addresses.from + index }}
                  </td>
                  <td class="align-middle">
                    <b>{{ address.name_en }}</b>
                  </td>
                  <td class="align-middle">
                    <b>{{ address.name_bn }}</b>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        address.status == 1 ? 'badge-success' : 'badge-danger'
                      "
                    >
                      {{ address.status == 1 ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(address.id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ addresses.from ? addresses.from : 0 }} to
                  {{ addresses.to ? addresses.to : 0 }} of
                  {{ addresses.total ? addresses.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="addresses"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Address'">
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <b-form-group label="Select Branch Type" label-for="type">
                  <validation-provider
                    #default="{ errors }"
                    name="type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="form.type"
                      placeholder="Select Branch Type"
                      :options="types"
                      label="name"
                      :reduce="(option) => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Branch Title English" label-for="name_en">
                  <validation-provider
                    #default="{ errors }"
                    name="name_en"
                    rules="required"
                  >
                    <b-form-input
                      id="name_en"
                      v-model="form.name_en"
                      name="name_en"
                      placeholder="Enter Your Branch Title English"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Branch Title Bangla" label-for="name_bn">
                  <validation-provider
                    #default="{ errors }"
                    name="name_bn"
                    rules="required"
                  >
                    <b-form-input
                      id="name_bn"
                      v-model="form.name_bn"
                      name="name_bn"
                      placeholder="Enter Your Branch Title English"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Address English" label-for="address_en">
                  <validation-provider
                    #default="{ errors }"
                    name="address_en"
                    rules="required"
                  >
                    <b-form-textarea
                      id="address_en"
                      v-model="form.address_en"
                      :state="errors.length > 0 ? false : null"
                      name="address_en"
                      placeholder="Enter Your Address English"
                      rows="5"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Address Bangla" label-for="address_bn">
                  <validation-provider
                    #default="{ errors }"
                    name="address_bn"
                    rules="required"
                  >
                    <b-form-textarea
                      id="address_bn"
                      v-model="form.address_bn"
                      :state="errors.length > 0 ? false : null"
                      name="address_bn"
                      placeholder="Enter Your Address Bangla"
                      rows="5"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Time Schedule" label-for="time_schedule">
                  <validation-provider
                    #default="{ errors }"
                    name="time_schedule"
                    rules="required"
                  >
                    <b-form-textarea
                      id="time_schedule"
                      v-model="form.time_schedule"
                      :state="errors.length > 0 ? false : null"
                      name="time_schedule"
                      placeholder="Enter Your Time Schedule"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Phone Number" label-for="phone_number">
                  <validation-provider
                    #default="{ errors }"
                    name="phone_number"
                    rules="required"
                  >
                    <b-form-textarea
                      id="phone_number"
                      v-model="form.phone_number"
                      :state="errors.length > 0 ? false : null"
                      name="phone_number"
                      placeholder="Enter Your Phone Number"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Email Address" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-textarea
                      id="email"
                      v-model="form.email"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                      placeholder="Enter Your Email Address"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Branch Map" label-for="map">
                  <validation-provider
                    #default="{ errors }"
                    name="map"
                    rules="required"
                  >
                    <b-form-textarea
                      id="map"
                      v-model="form.map"
                      :state="errors.length > 0 ? false : null"
                      name="map"
                      placeholder="Enter Your Branch Map"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  Status
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],

      form: {
        id: "",
        name_en: "",
        name_bn: "",
        address_en: "",
        address_bn: "",
        time_schedule: "",
        phone_number: "",
        map: "",
        email: "",
        type: null,
        status: true,
      },
      types: [
        { name: "Head Office", id: 1 },
        { name: "Campus", id: 2 },
        { name: "Branch Office", id: 3 },
      ],
      addresses: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.getResults();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const addresses = await this.callApi(
        "post",
        "/app/address?page=" + this.search.page,
        this.search
      );
      if (addresses.status == 200) {
        this.addresses = addresses.data.addresses;
      }
    },

    Close() {
      this.form.id = "";
      this.form.name_en = "";
      this.form.name_bn = "";
      this.form.address_en = "";
      this.form.address_bn = "";
      this.form.time_schedule = "";
      this.form.phone_number = "";
      this.form.map = "";
      this.form.type = null;
      this.form.status = true;
    },

    async edit(id) {
      const address = await this.callApi("post", "/app/address/edit/" + id);
      if (address.status == 200) {
        this.form.id = address.data.address.id;
        this.form.name_en = address.data.address.name_en;
        this.form.name_bn = address.data.address.name_bn;
        this.form.address_en = address.data.address.address_en;
        this.form.address_bn = address.data.address.address_bn;
        this.form.time_schedule = address.data.address.time_schedule;
        this.form.phone_number = address.data.address.phone_number;
        this.form.map = address.data.address.map;
        this.form.email = address.data.address.email;
        this.form.type = address.data.address.type;
        this.form.status = address.data.address.status == true ? true : false;
      }
    },

    add() {
      axios
        .post("/app/address/add", this.form)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.question)
              this.e(e.response.data.errors.question[0]);
            if (e.response.data.errors.answer)
              this.e(e.response.data.errors.answer[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "address.access") {
          this.permission.access = true;
        }

        if (permission.slug == "address.create") {
          this.permission.create = true;
        }

        if (permission.slug == "address.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "address.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
